import { createSlice, isPending } from "@reduxjs/toolkit";
import { THEME_CONFIG } from "configs/AppConfig";

const themeSlice = createSlice({
    name: 'themeSlice',
    initialState: {
        ...THEME_CONFIG
    },
    reducers: {
        toggleCollapsedNav: (state, action) => { state.navCollapsed = action.payload },
        onNavStyleChange: (state, action) => { state.sideNavTheme = action.payload },
        onLocaleChange: (state, action) => { state.locale = action.payload },
        onNavTypeChange: (state, action) => { state.navType = action.payload },
        onTopNavColorChange: (state, action) => { state.topNavColor = action.payload },
        onHeaderNavColorChange: (state, action) => { state.headerNavColor = action.payload },
        onMobileNavToggle: (state, action) => { state.mobileNav = action.payload },
        onSwitchTheme: (state, action) => { state.currentTheme = action.payload },
        onDirectionChange: (state, action) => { state.direction = action.payload },
    }
})

export default themeSlice.reducer
export const {
    toggleCollapsedNav,
    onNavStyleChange,
    onLocaleChange,
    onNavTypeChange,
    onTopNavColorChange,
    onHeaderNavColorChange,
    onMobileNavToggle,
    onSwitchTheme,
    onDirectionChange
} = themeSlice.actions
