import { createSlice, isPending } from "@reduxjs/toolkit";
import { STATUS, USER_DETAILS } from "redux/constants";
import { fulfilledSetterFunction, isFulfilledAction, isPendingAction, isRejectedAction, pendingSetterFunction, rejectedSetterFunction } from "redux/utils";
// import { userLogin } from "redux/thunks/authThunks";

const authSlice = createSlice({
    name: 'authSlice',
    initialState: {
        user: {
            data: JSON.parse(localStorage.getItem(USER_DETAILS)),
            status: STATUS.IDLE,
            error: null,
        }
    },
    extraReducers: (builder) => {
        builder.addMatcher(isPendingAction, pendingSetterFunction(getStateKey))
        builder.addMatcher(isFulfilledAction, fulfilledSetterFunction(getStateKey))
        builder.addMatcher(isRejectedAction, rejectedSetterFunction(getStateKey))
    },
})


function getStateKey(state, action) {
    const [, objectName] = action.type.split('/')
    // console.log(objectName)
    switch (objectName) {
        case 'login': return state.user
        default: return null
    }
}
export default authSlice.reducer