import React from "react";
import { Menu, Dropdown, Avatar } from "antd";
import { connect } from 'react-redux'
import {
  EditOutlined,
  SettingOutlined,
  ShopOutlined,
  QuestionCircleOutlined,
  LogoutOutlined,
  MailOutlined
} from '@ant-design/icons';
import Icon from 'components/util-components/Icon';
import { signOut } from 'redux/actions/Auth';
import { useHistory } from 'react-router-dom';

const menuItem = [
  // {
  //   title: "Edit Profile",
  //   icon: EditOutlined,
  //   path: "/"
  // },

  // {
  //   title: "Account Setting",
  //   icon: SettingOutlined,
  //   path: "/"
  // },
  // {
  //   title: "Billing",
  //   icon: ShopOutlined,
  //   path: "/"
  // },
  // {
  //   title: "Help Center",
  //   icon: QuestionCircleOutlined,
  //   path: "/"
  // }
]

export const NavProfile = ({ userDetails }) => {

  const history = useHistory()
  const logoutUser = () => {
    localStorage.clear()
    history.push('/auth/login')
  }
  const userInitials = userDetails.first_name[0] + userDetails.last_name[0]
  const userEmail = userDetails.email
  const profileImg = "/img/avatars/thumb-1.jpg";
  const profileMenu = (
    <div className="nav-profile nav-dropdown">
      <div className="nav-profile-body">
        <Menu>
          {menuItem.map((el, i) => {
            return (
              <Menu.Item key={i}>
                <a href={el.path}>
                  <Icon type={el.icon} />
                  <span className="font-weight-normal">{el.title}</span>
                </a>
              </Menu.Item>
            );
          })}
          <Menu.Item key={menuItem.length + 1}>
            <span>
              <MailOutlined />
              <span className="font-weight-normal">{userEmail}</span>
            </span>
          </Menu.Item>
          <Menu.Item key={menuItem.length + 2} onClick={e => logoutUser()}>
            <span>
              <LogoutOutlined />
              <span className="font-weight-normal">Sign Out</span>
            </span>
          </Menu.Item>
        </Menu>
      </div>
    </div>
  );
  return (
    <Dropdown placement="bottomRight" overlay={profileMenu} trigger={["click"]}>
      <Menu className="d-flex align-item-center" mode="horizontal">
        <Menu.Item key="profile">
          <Avatar shape="square" style={{ backgroundColor: '#F4C9BD' }}>
            {userInitials}
          </Avatar>
        </Menu.Item>
      </Menu>
    </Dropdown>
  );
}

export default connect(null, { signOut })(NavProfile)
