import {
  UnorderedListOutlined,
  BankOutlined,
  LaptopOutlined,
  UserAddOutlined,
} from '@ant-design/icons';
import { APP_PREFIX_PATH } from 'configs/AppConfig'

const dashBoardNavTree = [
  {
    key: 'product-list',
    // path: `${APP_PREFIX_PATH}/product-list`,
    title: 'Mappes Admin',
    // icon: UnorderedListOutlined,
    submenu: [
      {
        key: 'company-list',
        path: `${APP_PREFIX_PATH}/company-list`,
        title: 'Companies',
        icon: BankOutlined,
        submenu: []
      },
      {
        key: 'product-list',
        path: `${APP_PREFIX_PATH}/product-list`,
        title: 'Products',
        icon: LaptopOutlined,
        submenu: []
      },
      {
        key: 'admin',
        path: `${APP_PREFIX_PATH}/admin/add-companies`,
        title: 'Admin Operations',
        icon: UnorderedListOutlined,
        // breadcrumb: true,
        submenu: [{
          key: 'add-companies',
          path: `${APP_PREFIX_PATH}/admin/add-companies`,
          title: 'Add Companies',
          submenu: [],
          // icon: CaretRightOutlined,
        },
        {
          key: 'add-prospects',
          path: `${APP_PREFIX_PATH}/admin/add-prospects`,
          title: 'Add Prospects',
          submenu: [],
          icon: UserAddOutlined,
        },
        {
          key: 'tag-company-event',
          path: `${APP_PREFIX_PATH}/admin/tag-company-event`,
          title: 'Tag Companies',
          submenu: [],
          // icon: CaretRightOutlined,
        },
        {
          key: 'update-companies',
          path: `${APP_PREFIX_PATH}/admin/update-companies`,
          title: 'Update Companies',
          submenu: [],
          // icon: CaretRightOutlined,
        },
        {
          key: 'pre-register-users',
          path: `${APP_PREFIX_PATH}/admin/pre-register-users`,
          title: 'Pre-Register Users',
          submenu: [],
          // icon: CaretRightOutlined,
        }]
      }
    ]
  },
  //   {
  //   key: 'products',
  //   path: `${APP_PREFIX_PATH}/home`,
  //   title: 'Products',
  //   // icon: DashboardOutlined,
  //   breadcrumb: false,
  //   submenu: []
  // },

]

const navigationConfig = [
  ...dashBoardNavTree
]

export default navigationConfig;
