import { configureStore } from '@reduxjs/toolkit';
import authSlice from 'redux/reducers/authSlice';
import bulkUploadSlice from 'redux/reducers/bulkUploadSlice';
import { rootSplitApi } from 'redux/reducers/rootApiSlice';
import themeSlice from 'redux/reducers/themeSlice';

const store = configureStore({
  reducer: {
    auth: authSlice,
    theme: themeSlice,
    upload: bulkUploadSlice,
    
    [rootSplitApi.reducerPath]: rootSplitApi.reducer
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(rootSplitApi.middleware),
  devTools: process.env.NODE_ENV !== 'production',
})

export default store;