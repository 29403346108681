import { STATUS } from "redux/constants"

export function isPendingAction(action) {
    return action.type.endsWith('pending');
}

export function isFulfilledAction(action) {
    return action.type.endsWith('fulfilled');
}

export function isRejectedAction(action) {
    return action.type.endsWith('rejected');
}

export const pendingSetterFunction = (getStateKey) => (state, action) => {
    const key = getStateKey(state, action)
    if (key) {
        key.status = STATUS.PENDING
        key.error = null
    }
}

export const fulfilledSetterFunction = (getStateKey) => (state, action) => {
    const key = getStateKey(state, action)
    if (key) {
        key.status = STATUS.FULFILLED
        key.data = action.payload
        key.error = null
    }
}

export const rejectedSetterFunction = (getStateKey) => (state, action) => {
    const key = getStateKey(state, action)
    if (key) {
        key.status = STATUS.REJECTED
        key.error = action.payload
    }
}