import React, { Component } from 'react';
import { BellOutlined } from '@ant-design/icons';
import { Avatar, Drawer, Menu, Typography } from 'antd';
import ThemeConfigurator from './ThemeConfigurator';
import { connect } from "react-redux";
import { DIR_RTL } from 'constants/ThemeConstant';

export class NavPanel extends Component {
  state = { visible: false };

  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  render() {
    return (
      <>
        <Menu mode="horizontal">
          {/* <Menu.Item key="notification-icon">
            <BellOutlined className="nav-icon mr-0" />
          </Menu.Item>
          <Menu.Item key="user-name">
            <Typography.Paragraph level={6} className="nav-icon mr-0"> Kishan Vikani</Typography.Paragraph>
          </Menu.Item> */}
          <Menu.Item key="panel" onClick={this.showDrawer}>
            <span>
              <Avatar shape='square' className="nav-icon mr-0" style={{ backgroundColor: '#F4C9BD' }}>
                KV
              </Avatar>
            </span>
          </Menu.Item>
        </Menu>
        <Drawer
          title="User Settings"
          placement={this.props.direction === DIR_RTL ? 'left' : 'right'}
          width={350}
          onClose={this.onClose}
          visible={this.state.visible}
        >
          <ThemeConfigurator />
        </Drawer>
      </>
    );
  }
}

const mapStateToProps = ({ theme }) => {
  const { locale } = theme;
  return { locale }
};

export default connect(mapStateToProps)(NavPanel);