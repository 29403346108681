import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { API_BASE_URL } from 'configs/AppConfig'
import { AUTH_TOKEN } from 'redux/constants'
import { axiosBaseQuery } from 'redux/store/axiosBaseQuery'

export const rootSplitApi = createApi({
    baseQuery: axiosBaseQuery({
        baseUrl: `${API_BASE_URL}/v1/`,
    }),
    reducerPath: 'rootSplitApi',
    endpoints: () => ({}),
})