import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from 'components/shared-components/Loading';
import { APP_PREFIX_PATH } from 'configs/AppConfig'

export const AppViews = () => {
  return (
    <Suspense fallback={<Loading cover="content"/>}>
      <Switch>
        <Route path={`${APP_PREFIX_PATH}/admin/add-companies`} component={lazy(() => import(`./add-companies`))} />
        <Route path={`${APP_PREFIX_PATH}/admin/add-prospects`} component={lazy(() => import(`./add-prospects`))} />
        <Route path={`${APP_PREFIX_PATH}/admin/tag-company-event`} component={lazy(() => import(`./tag-companies-event`))} />
        <Route path={`${APP_PREFIX_PATH}/admin/update-companies`} component={lazy(() => import(`./update-companies`))} />
        <Route path={`${APP_PREFIX_PATH}/admin/pre-register-users`} component={lazy(() => import(`./pre-register-users`))} />
        <Route path={`${APP_PREFIX_PATH}/company-list`} component={lazy(() => import(`./company-list`))} />
        <Route path={`${APP_PREFIX_PATH}/company/:id`} component={lazy(() => import(`./company-details`))} />
        <Route path={`${APP_PREFIX_PATH}/product-list`} component={lazy(() => import(`./product-list`))} />
        <Route path={`${APP_PREFIX_PATH}/product/:id`} component={lazy(() => import(`./product-details`))} />
        <Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/admin/add-companies`} />
      </Switch>
    </Suspense>
  )
}

export default React.memo(AppViews);