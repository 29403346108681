import axios from 'axios'
import { AUTH_TOKEN } from 'redux/constants'
import { notification } from 'antd'

const GENERIC_SUCCESS = 'Operation completed successfully'
const GENERIC_FAILURE = 'Operation failed, please re-try !'

export const axiosBaseQuery = ({ baseUrl } = { baseUrl: '' }) =>
    async ({ url, method, data, params }) => {
        try {
            const result = await axios({
                url: baseUrl + url,
                method,
                data,
                params,
                headers: {
                    'Authorization': localStorage.getItem(AUTH_TOKEN)
                },
            })
            if (method && method !== 'GET') {
                if (result.data.success) {
                    if (result.data.messages) {
                        result.data.messages.forEach(({ message }) => {
                            notification.success({
                                message: 'Success',
                                description: message,
                            });
                        });
                    } else {
                        notification.success({
                            message: 'Success',
                            description: GENERIC_SUCCESS,
                        });
                    }
                } else {
                    if (result.data.messages) {
                        result.data.messages.forEach(({ message }) => {
                            notification.error({
                                message: 'Failed',
                                description: typeof message === "string" ? message : GENERIC_FAILURE ,
                            });
                        });
                    } else {
                        notification.error({
                            message: 'Failed',
                            description: GENERIC_FAILURE,
                        });
                    }
                }
                console.log(result)
            }
            return { data: result.data }
        } catch (axiosError) {
            const err = axiosError
            if (method && method !== 'GET') {
                notification.error({
                    message: 'Failed',
                    description: 'Server error, please re-try !',
                });
            }

            return {
                error: {
                    status: err.response?.status,
                    data: err.response?.data || err.message,
                },
            }
        }
    }