import { createSlice } from "@reduxjs/toolkit";
import { STATUS } from "redux/constants";
import { fulfilledSetterFunction, isFulfilledAction, isPendingAction, isRejectedAction, pendingSetterFunction, rejectedSetterFunction } from "redux/utils";


const uploadInitialData = {
    data: {}, status: STATUS.IDLE, error: null
}
const bulkUploadSlice = createSlice({
    name: 'bulkUploadSlice',
    initialState: {
        companyUpload: uploadInitialData,
        prospectUpload: uploadInitialData,
        companyEventTagUpload: uploadInitialData,
        updateCompanyUpload: uploadInitialData,
        preRegisterUserUpload: uploadInitialData,
    },
    reducers: {
        resetCompanyUpload: (state) => {
            state.companyUpload = uploadInitialData
        },
        resetProspectUpload: (state) => {
            state.prospectUpload = uploadInitialData
        },
        resetCompanyEventTagUpload: (state) => {
            state.companyEventTagUpload = uploadInitialData
        },
        resetUpdateCompanyUpload: (state) => {
            state.updateCompanyUpload = uploadInitialData
        },
        resetPreRegisterUserUpload: (state) => {
            state.preRegisterUserUpload = uploadInitialData
        }
    },
    extraReducers: (builder) => {
        builder.addMatcher(isPendingAction, pendingSetterFunction(getStateKey))
        builder.addMatcher(isFulfilledAction, fulfilledSetterFunction(getStateKey))
        builder.addMatcher(isRejectedAction, rejectedSetterFunction(getStateKey))
    },
})


function getStateKey(state, action) {
    const [, objectName] = action.type.split('/')
    switch (objectName) {
        case 'companyUpload': return state.companyUpload
        case 'prospectUpload': return state.prospectUpload
        case 'companyEventTagUpload': return state.companyEventTagUpload
        case 'updateCompanyUpload': return state.updateCompanyUpload
        case 'preRegisterUserUpload': return state.preRegisterUserUpload
        default: return null
    }
}

export const { resetCompanyUpload, resetProspectUpload, resetCompanyEventTagUpload, resetUpdateCompanyUpload, resetPreRegisterUserUpload } = bulkUploadSlice.actions
export default bulkUploadSlice.reducer